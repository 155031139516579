
import './App.css';
import page from './page.jpg';

function App() {
  return (
    <div className="container">
        <img src={page} alt="" className="page"/>
        <a href="https://jogos.arecreativa.com.br/"  target="_blank" className="portalJogos"/>
        <a href="https://play.google.com/store/apps/details?id=com.recreativa.app"  target="_blank" className="downloadOurApp"/>
        <a href="https://arecreativa.com.br/contato.html"  target="_blank" className="clickHere"/>
    </div>
  );
}

export default App;
